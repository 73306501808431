<template>
  <section class="section is-paddingless">
    <Navbar />

    <div class="container">
      <br><br><br>
      <figure class="center">
        <img src="@/assets/img/checkmark.svg" alt="Checkmark">
      </figure>
      <br>
      <div class="has-text-centered">
        <h1 class="title is-spaced" v-text="$t('on_the_list')" />
        <h2 class="subtitle" v-text="$t('subscribed_text')" />
        <hr class="page-break">
        <br>
        <div class="columns center is-mobile mb-0">
          <div class="column is-narrow">
            <a target="_blank" rel="noopener" href="https://t.me/morpher_io">
              <img class="is-32x32" src="@/assets/img/telegram.svg" alt="Telegram Icon">
            </a>
          </div>
          <div class="column is-narrow">
            <a target="_blank" rel="noopener" href="https://twitter.com/morpher_io">
              <img class="is-32x32" src="@/assets/img/twitter.svg" alt="Twitter Icon">
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Navbar from "@/components/partials/Navbar.vue"

export default {
  name: "Subscribed",
  components: {
    Navbar
  },
  head() {
    return {
      meta: [{ hid: "robots", name: "robots", content: "noindex" }]
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mt-10 {
  margin-top: 10px !important;
}
.page-break {
    width: 50%;
    margin: 0 auto;
}
.mb-0 {
  margin-bottom: 0px;
}
</style>
